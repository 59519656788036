import React from 'react';
import { styled } from 'styled-components';

const ContainerStyle = styled.div<{ size: string }>`
  position: relative;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
`;

const CircleStyle = styled.span<{ size: string }>`
  display: block;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  border: 0.5rem solid var(--grey-pale3);
  border-top: 0.5rem solid var(--twd-aqua);
  border-radius: 50%;
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 0;
  animation: spinTransition 1s linear infinite;

  @keyframes spinTransition {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface CircleLoaderProps {
  size?: string;
}

export function CircleLoader(props: CircleLoaderProps) {
  return (
    <ContainerStyle size={props.size || '7rem'}>
      <CircleStyle size={props.size || '7rem'} />
    </ContainerStyle>
  );
}
