import { SelectOption } from '../BaseSelect';
import React from 'react';
import {
  StyledSelectButton,
  IconTick,
  UnCheckedCheckBox,
  CheckedBox,
} from './SelectButton.styled';

interface SelectButtonProps<T extends object> {
  option: SelectOption<T>;
  isHoveringOver: boolean;
  onClick: (option: SelectOption<T>) => void;
  index: number;
  children: any;
  buttonRefs: any;
  disabled: boolean;
  multi?: boolean;
  scrollBarVisible: boolean;
}

export function SelectButton<T extends object>(props: SelectButtonProps<T>) {
  return (
    <StyledSelectButton
      ref={(el: any) => (props.buttonRefs.current[props.index] = el)}
      onClick={() => !props.disabled && props.onClick(props.option)}
      isHoveringOver={props.isHoveringOver}
      scrollBarVisible={props.scrollBarVisible}
      disabled={props.disabled}
    >
      {props.multi &&
        (props.option.isSelected ? <CheckedBox /> : <UnCheckedCheckBox />)}
      {props.children}
      {!props.multi && <IconTick checked={props.option.isSelected} />}
    </StyledSelectButton>
  );
}
