import { useKeyControl, useOutsideClick } from '../Select.hooks';
import { BaseSelectDiv } from './BaseSelect.styled';
import { createContext, useContext } from 'react';

export interface SelectOption<T extends object> {
  id: number;
  option: T;
  displayedValue: string;
  isSelected: boolean;
}

interface BaseSelectProps<T extends object> {
  options: SelectOption<T>[];
  onClick: (option: SelectOption<T>) => void;
  callback: () => void;
  children: any;
}

interface BaseSelectContextType {
  buttonRefs: any;
  hoverIndex: number;
}

const BaseSelectContext = createContext<BaseSelectContextType | undefined>(
  undefined
);

export const useBaseSelectContext = () => {
  const context = useContext(BaseSelectContext);
  if (!context) {
    throw new Error('No context given');
  }
  return context;
};

export function BaseSelect<T extends object>(props: BaseSelectProps<T>) {
  const { buttonRefs, hoverIndex, setHoverIndex, handleKeyDown } =
    useKeyControl(props.options, props.onClick);
  const ref = useOutsideClick(props.callback, setHoverIndex);

  return (
    <BaseSelectDiv
      className={'Select'}
      ref={ref}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <BaseSelectContext.Provider value={{ buttonRefs, hoverIndex }}>
        {props.children}
      </BaseSelectContext.Provider>
    </BaseSelectDiv>
  );
}
