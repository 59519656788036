import { styled } from 'styled-components';
import { ReactComponent as IconTickSVG } from '../../../../assets/Icon_Tick.svg';
import { ReactComponent as CheckBoxUncheckedSVG } from '../../../../assets/CheckBox_unchecked.svg';
import { ReactComponent as CheckBoxCheckedSVG } from '../../../../assets/CheckBox_checked.svg';

export const StyledSelectButton = styled.button<{
  isHoveringOver: boolean;
  scrollBarVisible: boolean;
}>`
  width: ${(props) => `calc(100% - ${props.scrollBarVisible ? '8px' : '0px'})`};
  height: 40px;
  padding: 12px 10px 12px 10px;
  margin-right: ${(props) => (props.scrollBarVisible ? '8px' : '0px')};
  border: 0;
  position: relative;
  z-index: 3;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: ${(props) =>
    !props.isHoveringOver ? 'white' : 'var(--aqua-pale2)'};
  color: ${(props) =>
    props.disabled ? 'var(--grey-pale3)' : 'var(--web-black)'};

  &:hover {
    background: ${(props) => (props.disabled ? 'white' : 'var(--aqua-pale2)')};
    cursor: ${(props) => (!props.disabled ? 'pointer' : 'initial')};
  }

  &:focus-visible {
    border: 0;
  }
`;

export const IconTick = styled(IconTickSVG)<{ checked: boolean }>`
  width: 20px;
  height: 20px;
  opacity: ${(props) => (props.checked ? '1' : '0')};
`;

export const CheckBox = styled.svg`
  width: 20px;
  height: 20px;
`;

export const UnCheckedCheckBox = styled(CheckBoxUncheckedSVG)`
  ${CheckBox};
  fill: var(--grey-pale3);

  ${StyledSelectButton}:hover & {
    fill: var(--twd-aqua);
  }
`;

export const CheckedBox = styled(CheckBoxCheckedSVG)`
  ${CheckBox}
`;

export const ButtonContentDiv = styled.div`
  width: calc(100% - 30px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
`;
