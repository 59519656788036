import { BaseSelectModal } from '../../BaseSelect/BaseSelectModal/BaseSelectModal';
import { SelectOption } from '../../BaseSelect/BaseSelect';
import React, { ReactElement } from 'react';
import { ButtonContentDiv } from '../../BaseSelect/SelectButton/SelectButton.styled';

interface AutoCompleteModalProps<T extends object> {
  options: SelectOption<T>[];
  clickedOnOption: (option: SelectOption<T>) => void;
  extraSection?: ReactElement;
  extraButtonContent?: (option: SelectOption<T>) => ReactElement;
  disableOption?: (value: T) => boolean;
}

export function AutoCompleteModal<T extends object>(
  props: AutoCompleteModalProps<T>
) {
  const buttonContent = (option: SelectOption<T>) => {
    return (
      <ButtonContentDiv>
        {option.displayedValue}
        {props.extraButtonContent && props.extraButtonContent(option)}
      </ButtonContentDiv>
    );
  };

  return (
    <BaseSelectModal
      options={props.options}
      onClick={props.clickedOnOption}
      buttonContent={buttonContent}
      extraSection={props.extraSection}
      disableOption={props.disableOption}
    />
  );
}
