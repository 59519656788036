import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { SelectOption } from '../../BaseSelect/BaseSelect';
import { BaseSelectBox } from '../../BaseSelect/BaseSelectBox/BaseSelectBox';

interface MultiSelectBoxProps<T extends object> {
  options: SelectOption<T>[];
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  itemType: string;
  placeholder?: string;
  disabled?: boolean;
}

export function MultiSelectBox<T extends object>(
  props: MultiSelectBoxProps<T>
) {
  const [displayedText, setDisplayedText] = useState<string>('');

  useEffect(() => {
    const selectedOptions = props.options.filter(
      (option: SelectOption<T>) => option.isSelected
    );
    if (selectedOptions.length === 0) {
      setDisplayedText('');
    } else if (selectedOptions.length === 1) {
      setDisplayedText(selectedOptions[0].displayedValue);
    } else {
      const isAllSelected = selectedOptions.length === props.options.length;
      setDisplayedText(
        `${isAllSelected ? `All ${props.itemType}` : `${selectedOptions.length} ${props.itemType}`} selected`
      );
    }
  }, [props.options]);

  return (
    <BaseSelectBox
      boxType={'text'}
      displayedText={displayedText}
      openModal={props.openModal}
      setOpenModal={props.setOpenModal}
      placeholder={props.placeholder}
      disabled={props.disabled}
    />
  );
}
