import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  PhaseData,
  PlanningData,
  PlanningSettings,
  PlanningType,
  RowEntry,
  WeekData,
} from './PlanningType';
import { createPlanningEntries } from './Functions/DatabaseFunctions';
import { GridColDef, GridRowId, useGridApiRef } from '@mui/x-data-grid-premium';
import {
  ActualCostingEntry,
  CostEstimateEntry,
  ProjectType,
} from '../../App.types.';
import { addDays, isBefore, startOfWeek } from 'date-fns';
import { fromStringToDate } from './Functions/TimeFunctions';
import { getMaxPlannedDate, getWeekColumns } from './PlanningTable/Columns';

export const useProjectData = (projectData: ProjectType) => {
  const [phaseData, setPhaseData] = useState<PhaseData>({});
  // Actual costing and cost estimate by phase and by week
  const [weekData, setWeekData] = useState<Map<[string, string], { actualCosting: number, costEstimate: number, actualCostingHours: number }>>(new Map());

  useEffect(() => {
    const newPhaseData: PhaseData = {};
    const newWeekData: WeekData = new Map<[string, string], { actualCosting: number, costEstimate: number, actualCostingHours: number }>();

    projectData.actualCosting.forEach((actualCosting: ActualCostingEntry) => {
      const phaseCode = actualCosting.PhaseCode;
      const yearWeek = actualCosting.YearWeek;
      const key: [string, string] = [phaseCode, yearWeek]; // Use a tuple [phaseCode, yearWeek] as the key
      if (!newPhaseData[phaseCode]) {
        newPhaseData[phaseCode] = { actualCosting: 0, costEstimate: 0, actualCostingHours: 0 };
      }
      newPhaseData[phaseCode].actualCosting += actualCosting.SalesValue;
      newPhaseData[phaseCode].actualCostingHours += actualCosting.Hours;

      if (!newWeekData.has(key)) {
        newWeekData.set(key, { actualCosting: 0, costEstimate: 0, actualCostingHours: 0 });
      }

      // Sum up actual costing by phase and week
      const existingData = newWeekData.get(key)!;
      existingData.actualCosting += actualCosting.SalesValue;
      existingData.actualCostingHours += actualCosting.Hours;
      newWeekData.set(key, existingData);
    });

    projectData.costEstimate.forEach((costEstimateEntry: CostEstimateEntry) => {
      const phaseCode = costEstimateEntry.PhaseCode;
      const yearWeek = costEstimateEntry.YearWeek;
      const key: [string, string] = [phaseCode, yearWeek]; // Use a tuple [phaseCode, yearWeek] as the key

      if (!newPhaseData[phaseCode]) {
        newPhaseData[phaseCode] = { actualCosting: 0, costEstimate: 0, actualCostingHours: 0 };
      }
      newPhaseData[phaseCode].costEstimate += costEstimateEntry.SalesValue;

      if (!newWeekData.has(key)) {
        newWeekData.set(key, { actualCosting: 0, costEstimate: 0, actualCostingHours: 0 });
      }

      // Sum up cost estimate by phase and week
      const existingData = newWeekData.get(key)!;
      existingData.costEstimate += costEstimateEntry.SalesValue;
      newWeekData.set(key, existingData);
    });

    setPhaseData(newPhaseData);
    setWeekData(newWeekData);
  }, [projectData]);

  return { phaseData, weekData };
};

export const usePlanningData = (
  projectData: ProjectType,
  selectedPlanning: PlanningType | null,
  setIsLoading: (isLoading: boolean) => void,
  inEditMode: boolean
) => {
  const [planningData, setPlanningData] = useState<PlanningData>({
    rows: [],
    lastDate: new Date(),
    weekColumns: [],
  });
  useEffect(() => {
    const getPlanningData = async () => {
      setIsLoading(true);
      let rows: RowEntry[] = [];
      let weekColumns: GridColDef<RowEntry>[] = [];
      let lastDate = new Date();
      if (selectedPlanning && Object.keys(projectData.phases).length !== 0) {
        const planningEntryData = await createPlanningEntries(
          selectedPlanning,
          projectData.phases
        );
        rows = planningEntryData[0];
        lastDate = planningEntryData[1];
        weekColumns = getWeekColumns(
          projectData,
          getMaxPlannedDate(planningEntryData[0]),
          inEditMode
        );
      }
      setPlanningData({
        rows: rows,
        lastDate: lastDate,
        weekColumns: weekColumns,
      });
      setIsLoading(false);
    };

    void getPlanningData();
  }, [projectData, selectedPlanning, inEditMode]);

  return { planningData };
};

export const defaultColors = [
  '#ffffff',
  '#90ee90',
  '#ff6e67',
  '#ecf7fe',
  '#ffc857',
  '#d7b9ff',
  '#f7a3ff',
  '#ffbfa3',
];

export const usePlanningUpdates = (
  projectData: ProjectType,
  planningData: PlanningData,
  setUpdatedRows: Dispatch<SetStateAction<RowEntry[]>>,
  setChangeIsMade: Dispatch<SetStateAction<boolean>>
) => {
  const apiRef = useGridApiRef();
  const colorRef = useRef<string[]>(defaultColors);
  let idCounter = planningData.rows.length + 1;
  let lastWeek = startOfWeek(planningData.lastDate, { weekStartsOn: 1 });
  const isWeekClicked = useRef(true);
  const selectedDate = useRef(new Date());

  useEffect(() => {
    const newColors = defaultColors;
    planningData.rows.forEach((row: RowEntry) => {
      if (row.color && !newColors.includes(row.color)) {
        newColors.push(row.color);
      }
    });
    colorRef.current = newColors;
    changePlanningSettings(selectedDate.current, isWeekClicked.current);
  }, [planningData]);

  function addRow(id: GridRowId) {
    if (apiRef.current) {
      const clickedRow: RowEntry | null = apiRef.current.getRow(id);
      if (clickedRow) {
        apiRef.current.updateRows([
          {
            partId: clickedRow.partId,
            part: clickedRow.part,
            id: idCounter,
            rate: 'E',
            employee: '',
            task: 'Enter description here',
          },
        ]);
        setUpdatedRows((oldUpdatedRows) => [
          ...oldUpdatedRows,
          {
            partId: clickedRow.partId,
            part: clickedRow.part,
            id: idCounter,
            rate: 'E',
            employee: '',
            task: 'Enter description here',
          },
        ]);
        idCounter += 1;
      }
    }
  }

  function deleteRow(id: GridRowId) {
    if (apiRef.current) {
      const clickedRow: RowEntry | null = apiRef.current.getRow(id);
      if (clickedRow) {
        const rowsModels = apiRef.current.getRowModels();
        const rows: RowEntry[] = Array.from(rowsModels.values()) as RowEntry[];
        const phaseRows = rows.filter(
          (row) => row.partId === clickedRow.partId
        );
        if (phaseRows.length < 2) {
          addRow(id);
        }
        apiRef.current.updateRows([{ id: id, _action: 'delete' }]);
        setUpdatedRows((oldUpdatedRows: RowEntry[]) =>
          oldUpdatedRows.filter(
            (oldUpdatedRow: RowEntry) => oldUpdatedRow.id !== id
          )
        );
        setChangeIsMade(true);
      }
    }
  }

  function copyRow(id: GridRowId) {
    if (apiRef.current) {
      const clickedRow: RowEntry | null = apiRef.current.getRow(id);
      if (clickedRow) {
        const { id, ...rest } = clickedRow;
        apiRef.current.updateRows([{ id: idCounter, ...rest }]);
        setUpdatedRows((oldUpdatedRows) => [
          ...oldUpdatedRows,
          { id: idCounter, ...rest },
        ]);
        setChangeIsMade(true);
        idCounter += 1;
      }
    }
  }

  function changeRowColor(id: GridRowId, color: string) {
    if (apiRef.current) {
      if (color && !colorRef.current.includes(color)) {
        colorRef.current.push(color);
      }
      apiRef.current.updateRows([{ id: id, color: color }]);
      setUpdatedRows((oldUpdatedRows: RowEntry[]) =>
        oldUpdatedRows.map((oldUpdatedRow: RowEntry) => {
          if (oldUpdatedRow.id === id) {
            return { ...oldUpdatedRow, color: color };
          }
          return oldUpdatedRow;
        })
      );
      setChangeIsMade(true);
    }
  }

  function addColumn() {
    if (apiRef.current) {
      const columns = apiRef.current.getAllColumns();
      lastWeek = addDays(lastWeek, 7);
      const newWeekColumns = getWeekColumns(projectData, lastWeek, true);
      apiRef.current.updateColumns([...columns, ...newWeekColumns]);
      changePlanningSettings(selectedDate.current, isWeekClicked.current);
    }
  }

  function changePlanningSettings(date: Date, weekView: boolean) {
    if (apiRef.current) {
      isWeekClicked.current = weekView;
      selectedDate.current = date;
      const columns = apiRef.current.getAllColumns();
      if (columns.length > 0) {
        const currentWeek = startOfWeek(date, { weekStartsOn: 1 });
        const planningSettings = columns.reduce(
          (acc: PlanningSettings, column: GridColDef) => {
            const fieldName = startOfWeek(fromStringToDate(column.field), {
              weekStartsOn: 1,
            });
            const className: string = (column.cellClassName as string) || '';
            if (className.includes('week')) {
              acc.aggregation[column.field] = 'valueWeek';
            }
            if (isBefore(fieldName, currentWeek)) {
              acc.visibility[column.field] = false;
            } else if (['M', 'T', 'W', 'F'].includes(column.headerName || '')) {
              acc.visibility[column.field] = !weekView;
            }
            return acc;
          },
          { visibility: {}, aggregation: {} }
        );
        apiRef.current.setAggregationModel({
          planned: 'valueWeek',
          total: 'valueWeek',
          ...planningSettings.aggregation,
        });
        apiRef.current.setColumnVisibilityModel({
          part: false,
          ...planningSettings.visibility,
        });
      }
    }
  }

  return {
    apiRef,
    colorRef,
    addRow,
    deleteRow,
    copyRow,
    addColumn,
    changePlanningSettings,
    changeRowColor,
  };
};
