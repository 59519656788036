import { useChangeToSelectOption } from '../Select.hooks';
import React, { ReactElement, useEffect, useState } from 'react';
import { BaseSelect, SelectOption } from '../BaseSelect/BaseSelect';
import { AutoCompleteBox } from './AutoCompleteBox/AutoCompleteBox';
import { AutoCompleteModal } from './AutoCompleteModal/AutoCompleteModal';

export interface AutoCompleteProps<T extends object> {
  options: T[];
  optionLabels: (option: T) => string;
  onChange: (value: T | null) => void;
  placeholder?: string;
  disabled?: boolean;
  autoSelectIndex?: number;
  extraSection?: ReactElement;
  extraButtonContent?: (option: SelectOption<T>) => ReactElement;
  disableOption?: (value: T) => boolean;
  flickering?: boolean;
}

export function AutoComplete<T extends object>(props: AutoCompleteProps<T>) {
  const changedOption = (option: SelectOption<T>) => {
    setInputValue(option.displayedValue);
    setLastValidValue(option.displayedValue);
    setIsOptionChosen(true);
  };

  const clickedOnOption = (option: SelectOption<T>) => {
    if (
      !props.disableOption ||
      (props.disableOption && !props.disableOption(option.option))
    ) {
      if (!option.isSelected) {
        clickOnOption(option);
        changedOption(option);
      }
      setOpenModal(false);
    }
  };

  const { selectOptions, clickOnOption } = useChangeToSelectOption(
    props.options,
    props.optionLabels,
    (option: SelectOption<T>) => {
      changedOption(option);
      props.onChange(option.option);
    },
    props.autoSelectIndex
  );

  const [openModal, setOpenModal] = useState<boolean>(false);

  const [filteredOptions, setFilteredOptions] = useState<SelectOption<T>[]>([]);

  const [inputValue, setInputValue] = useState<string>('');
  const [lastValidValue, setLastValidValue] = useState<string>('');
  const [isOptionChosen, setIsOptionChosen] = useState<boolean>(true);

  useEffect(() => {
    if (props.options.length === 0) {
      setInputValue('');
      setLastValidValue('');
    }
    setOpenModal(false);
  }, [props.options]);

  useEffect(() => {
    setFilteredOptions(
      selectOptions.filter((option: SelectOption<T>) => {
        return isOptionChosen
          ? true
          : option.displayedValue
              .toLowerCase()
              .includes(inputValue.toLowerCase());
      })
    );
  }, [selectOptions, inputValue, isOptionChosen]);

  const onValueChange = (value: string) => {
    setInputValue(value);
    setIsOptionChosen(false);
  };

  const clickAwayFunction = () => {
    setInputValue(lastValidValue);
    setIsOptionChosen(true);
    setOpenModal(false);
  };

  return (
    <BaseSelect
      options={filteredOptions}
      onClick={clickedOnOption}
      callback={clickAwayFunction}
    >
      <AutoCompleteBox
        inputValue={inputValue}
        onValueChange={onValueChange}
        openModal={openModal}
        setOpenModal={setOpenModal}
        placeholder={props.placeholder}
        disabled={props.disabled}
        flickering={props.flickering}
      />
      {openModal && (
        <AutoCompleteModal
          options={filteredOptions}
          clickedOnOption={clickedOnOption}
          extraSection={props.extraSection}
          extraButtonContent={props.extraButtonContent}
          disableOption={props.disableOption}
        />
      )}
    </BaseSelect>
  );
}
