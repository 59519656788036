import { styled } from 'styled-components';

interface ToggleButtonsProps {
  firstButtonText: string;
  secondButtonText: string;
  value: boolean;
  onChange: (p: boolean) => void;
  width?: string;
  disable?: boolean;
}

export function ToggleButtons(props: ToggleButtonsProps) {
  return (
    <ToggleButtonsDiv width={props.width}>
      <LeftButton
        isSelected={props.value}
        disabled={props.disable}
        onClick={() => props.onChange(true)}
      >
        {props.firstButtonText}
      </LeftButton>
      <RightButton
        isSelected={!props.value}
        disabled={props.disable}
        onClick={() => props.onChange(false)}
      >
        {props.secondButtonText}
      </RightButton>
    </ToggleButtonsDiv>
  );
}

const ToggleButtonsDiv = styled.div<{ width?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: ${(props) => (props.width ? props.width : '213px')};
  padding: 0;
  margin: 0;
`;

const ToggleButton = styled.button<{ isSelected: boolean }>`
  font: inherit;
  height: 100%;
  width: 50%;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${(props) => (props.isSelected ? 'bold' : 'initial')};
  cursor: ${(props) =>
    props.isSelected || props.disabled ? 'default' : 'pointer'};
  color: ${(props) =>
    props.isSelected
      ? 'white'
      : props.disabled
        ? 'var(--grey-pale3)'
        : 'var(--twd-aqua)'};
  background: ${(props) =>
    props.isSelected
      ? props.disabled
        ? 'var(--grey-pale3)'
        : 'var(--twd-aqua)'
      : 'white'};
  box-shadow: ${(props) =>
    props.disabled
      ? 'inset 0 0 0 2px var(--grey-pale3)'
      : 'inset 0 0 0 2px var(--twd-aqua)'};
  z-index: ${(props) => (props.isSelected ? 2 : 1)};

  &:hover {
    background: ${(props) =>
      !props.isSelected && !props.disabled && 'var(--aqua-pale2)'};
  }
`;

const LeftButton = styled(ToggleButton)`
  border-radius: 8px 0 0 8px;
`;

const RightButton = styled(ToggleButton)`
  border-radius: 0 8px 8px 0;
`;
