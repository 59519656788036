import { styled } from 'styled-components';

export const BaseSelectDiv = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: inherit;
  border: 0;
  outline: 0;
`;
