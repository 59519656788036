import { useEffect, useState } from 'react';
import { DetailedPhasesInput, PhasesInput } from './DetailsPerPhase';
import {
  InputFieldsProps,
  PlanningInputProps,
  WeekNumberProps,
  WeekNumberType,
} from './InputType';
import { styled } from 'styled-components';
import { AutoComplete } from '../../Select/AutoComplete/AutoComplete';
import { PlanningType } from '../../Planning/PlanningType';
import { Tooltip } from '@mui/material';

const CardDiv = styled.div`
  background-color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: calc(50% - 45px);
  margin-right: 5px;
  padding: 20px;
  border-radius: 8px;
  gap: 20px;
  box-shadow: inset 0 0 0 1px var(--grey-pale3);
`;

const InputDiv = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 50px;
  gap: 10px;
  width: 100%;
`;

const WrappedAutoCompleteDiv = styled.div`
  height: 40px;
  width: 100%;
  min-width: 150px;
  background: white;
`;

function WeekNumberInput(props: WeekNumberProps) {
  const [weeks, setWeeks] = useState<WeekNumberType[]>([]);
  const [selectedWeekIndex, setSelectedWeekIndex] = useState<
    number | undefined
  >(0);

  useEffect(() => {
    const firstWeekWithSpent: number = props.weeks.findIndex(
      (week: WeekNumberType) => !week.Disabled
    );
    setSelectedWeekIndex(
      props.projectData.lumpsum || firstWeekWithSpent === -1
        ? undefined
        : firstWeekWithSpent
    );
    setWeeks(props.weeks);
  }, [props.weeks]);

  return (
    <WrappedAutoCompleteDiv>
      <AutoComplete
        options={weeks}
        optionLabels={(week: WeekNumberType) => week.YearWeek}
        onChange={(value) => {
          props.changeInputs({ week: value });
        }}
        placeholder={'Select Week'}
        disabled={
          props.projectData.projectId === '' || props.projectData.lumpsum
        }
        autoSelectIndex={selectedWeekIndex}
        disableOption={(week: WeekNumberType) => week.Disabled}
      />
    </WrappedAutoCompleteDiv>
  );
}

const NoPlanning: PlanningType = {
  id: '-1',
  project_id: '-1',
  name: 'No Planning',
};
function PlanningInput(props: PlanningInputProps) {
  const [reportPlanningOptions, setReportPlanningOptions] = useState<
    PlanningType[]
  >([]);

  useEffect(() => {
    if (props.projectData.projectId) {
      let reportPlanningList = [NoPlanning];
      if (props.islatestWeek) {
        reportPlanningList.push(...props.plannings);
      }
      setReportPlanningOptions(reportPlanningList);
    }
  }, [props.projectData, props.plannings, props.islatestWeek]);

  return (
    <Tooltip
      title={
        'It is not possible to create a budget prognosis of the weeks before the last week, since these numbers are already final'
      }
      placement={'top'}
      arrow
    >
      <WrappedAutoCompleteDiv>
        <AutoComplete
          options={reportPlanningOptions}
          optionLabels={(planning: PlanningType) => planning.name}
          onChange={(planning: PlanningType | null) => {
            if (planning) props.changeInputs({ planning: planning });
          }}
          placeholder={'Select Planning'}
          autoSelectIndex={0}
          disabled={
            props.projectData.projectId === '' ||
            !props.islatestWeek ||
            props.projectData.lumpsum
          }
        />
      </WrappedAutoCompleteDiv>
    </Tooltip>
  );
}

export function InputFields(props: InputFieldsProps) {
  const [hasDetailedPhases, setHasDetailedPhases] = useState<boolean>(false);

  useEffect(() => {
    setHasDetailedPhases(false);
  }, [props.reportData.week]);

  useEffect(() => {
    if (hasDetailedPhases) {
      const phaseNames: string[] = Object.values(props.projectData.phases).map(
        (phaseName: string) => phaseName
      );
      props.changeInputs({ phases: phaseNames });
    } else {
      props.changeInputs({ phases: [] });
    }
  }, [hasDetailedPhases, props.projectData]);

  const updatePhaseList = (newPhase: string) => {
    // Function that selects phases (included in report).
    let phasesList = props.reportData.phases;
    const phaseIndex = phasesList.indexOf(newPhase);
    let newPhaseList = [...phasesList, newPhase];
    if (phaseIndex !== -1) {
      newPhaseList = phasesList.filter((phase: string) => newPhase !== phase);
    }
    props.changeInputs({ phases: newPhaseList });
  };

  return (
    <CardDiv>
      <InputDiv>
        <WeekNumberInput
          projectData={props.projectData}
          weeks={props.weeks}
          changeInputs={props.changeInputs}
        />
        <PlanningInput
          projectData={props.projectData}
          plannings={props.plannings}
          changeInputs={props.changeInputs}
          islatestWeek={
            props.reportData.week
              ? props.reportData.week.YearWeek === props.weeks[0].YearWeek
              : false
          }
        />
        <PhasesInput
          hasDetailedPhases={hasDetailedPhases}
          setHasDetailedPhases={setHasDetailedPhases}
          isDisabled={
            props.projectData.projectId === '' ||
            !props.reportData.week ||
            props.projectData.lumpsum
          }
        />
      </InputDiv>
      {hasDetailedPhases && (
        <DetailedPhasesInput
          projectData={props.projectData}
          selectedPhases={props.reportData.phases}
          updatePhaseList={updatePhaseList}
        />
      )}
    </CardDiv>
  );
}
