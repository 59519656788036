import { styled } from 'styled-components';
import { StyledSelectButton } from '../Select/BaseSelect/SelectButton/SelectButton.styled';

export const IconButton = styled.button<{
  hoverColor: string;
  normalColor?: string;
  opacity?: number;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
  border: 0;
  svg {
    fill: ${(props) => props.normalColor || 'var(--grey-pale3)'};
  }

  &:hover {
    opacity: ${(props) => (props.opacity ? props.opacity : 1)};
  }

  &:hover {
    svg {
      fill: ${(props) => props.hoverColor};
    }
  }
`;

export const PlanningIconButton = styled(IconButton)`
  opacity: 0;
  ${StyledSelectButton}:hover & {
    opacity: 1;
  }
`;
