import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { CircularProgress } from '@mui/material';
import '../../../styles/PDFBox.css';
import FakePDF from '../../../assets/PDFexport.svg';
import BRTStart from '../../../assets/BRT_start.png';
import { PDFViewerProps } from './OutputType';
import { styled } from 'styled-components';
import { CircleLoader } from '../../CircleLoader/CircleLoader';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const FakePDFImg = styled.img`
  background-color: white;
  margin-right: 20px;
  margin-left: 20px;
  width: calc(100% - 40px);
  height: 100%;
`;

export function PDFViewer(props: PDFViewerProps) {
  const [numPages, setNumPages] = useState<number>(0);
  const [screenScale, setScreenScale] = useState<number>(
    window.devicePixelRatio || 1
  );

  useEffect(() => {
    const updateScreenScale = () => {
      setScreenScale(window.devicePixelRatio || 1);
    };

    window.addEventListener('resize', updateScreenScale);

    return () => {
      window.removeEventListener('resize', updateScreenScale);
    };
  }, []);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
  };

  const getPageStyle = (numPages: number) => {
    if (numPages === 1) {
      return 'pdfFirstPage';
    } else {
      return 'pdfPage';
    }
  };

  const scale = 1.3 / screenScale;

  return (
    <>
      {props.loading ? (
        <>
          <ReplacementDiv>
            <LoaderDiv>
              <CircleLoader />
            </LoaderDiv>
            <FakePDFImg src={FakePDF} alt="" />
          </ReplacementDiv>
        </>
      ) : props.pdfFile ? (
        <Document
          className={'styledDocument'}
          file={props.pdfFile as File}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {[...Array(numPages || 0)].map((_, index) => (
            <Page
              key={`page_${index + 1}`}
              className={getPageStyle(numPages)}
              scale={scale}
              pageNumber={index + 1}
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />
          ))}
        </Document>
      ) : (
        <img
          src={BRTStart}
          style={{ height: '650px', margin: 'auto', paddingBottom: '110px' }}
          alt=""
        />
      )}
    </>
  );
}

const ReplacementDiv = styled.div`
  position: relative;
`;

const LoaderDiv = styled.div`
  opacity: 0.7;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
