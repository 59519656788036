import { styled } from 'styled-components';
import { ReactComponent as SaveIcon } from '../../assets/Save.svg';
import { ReactComponent as EditIcon } from '../../assets/Edit.svg';
import { ReactComponent as LockIcon } from '../../assets/Lock.svg';
import { ReactComponent as ExitIcon } from '../../assets/Exit.svg';
import { PlanningType, RowEntry } from './PlanningType';
import { UserType } from '../../App.types.';
import useScrollbarSize from 'react-scrollbar-size';
import { Tooltip } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';

interface EditModeButtonsProps {
  updatedRows: RowEntry[];
  changeIsMade: boolean;
  setChangeIsMade: (changeIsMade: boolean) => void;
  selectedPlanning: PlanningType;
  inEditMode: boolean;
  alreadyInUse: string;
  setIsloading: (isLoading: boolean) => void;
  enterEditMode: (selectedPlanning: PlanningType) => Promise<void>;
  leaveEditMode: (selectedPlanning: PlanningType) => Promise<void>;
  setOpenNotSavedModal: (openNotSaveModal: boolean) => void;
  currentUser: UserType | null;
  savePlanning: (planningId: string) => Promise<void>;
  flickerEditButton: boolean;
  setFlickerEditButton: Dispatch<SetStateAction<boolean>>;
}

export function EditModeButtons(props: EditModeButtonsProps) {
  const { width } = useScrollbarSize();

  return (
    <>
      <EditModeSection scrollbarWidth={width}>
        <ButtonsDiv>
          <Tooltip
            title={props.inEditMode ? 'Leave edit mode' : 'Enter edit mode'}
            placement={'top'}
            arrow
          >
            <StyledButton
              onClick={async () => {
                props.setFlickerEditButton(false);
                if (props.inEditMode) {
                  if (props.changeIsMade) {
                    props.setOpenNotSavedModal(true);
                  } else {
                    await props.leaveEditMode(props.selectedPlanning);
                  }
                } else {
                  await props.enterEditMode(props.selectedPlanning);
                }
              }}
              disabled={props.alreadyInUse !== ''}
              flickerEditButton={props.flickerEditButton}
            >
              {props.alreadyInUse !== '' ? (
                <LockIcon />
              ) : props.inEditMode ? (
                <ExitIcon style={{ fill: 'white'}}/>
              ) : (
                <EditIcon style={{ fill: 'white' }} />
              )}
            </StyledButton>
          </Tooltip>
          <Tooltip title={'Save planning'} placement={'top'} arrow>
            <StyledButton
              changeIsMade={props.inEditMode && props.changeIsMade}
              onClick={() => props.savePlanning(props.selectedPlanning.id)}
              disabled={!props.inEditMode || !props.changeIsMade}
            >
              <SaveIcon />
            </StyledButton>
          </Tooltip>
        </ButtonsDiv>
      </EditModeSection>
    </>
  );
}

const EditModeSection = styled.div<{ scrollbarWidth: number }>`
  position: sticky;
  bottom: 0;
  margin-top: 20px;
  margin-bottom: 8px;
  display: flex;
  justify-content: end;
  z-index: 2;
`;

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 180px;
  z-index: 2;
`;

const StyledButton = styled.button<{
  changeIsMade?: boolean;
  flickerEditButton?: boolean;
}>`
  width: 80px;
  height: 80px;
  border-radius: 40px;
  background: ${(props) => (props.changeIsMade ? 'var(--twd-signal)' : 'var(--twd-aqua)')};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  svg {
    width: 50px;
    height: 50px;
  }
  
  &:hover {
    background: ${(props) => (props.changeIsMade ? 'var(--twd-signal) ' : 'rgb(0, 145, 201)')};
  }
  
  &:disabled {
    cursor: initial;
    background: var(--grey-pale3);
  }
  
  animation: ${(props) => (props.changeIsMade || props.flickerEditButton) && 'changeAnimation 1s cubic-bezier(.24,0,.38,1) infinite'};
  
  @keyframes changeAnimation {
    0%, 100% {
      opacity: 1
    }
    50% {
      opacity: 0.5
    }
  },
`;
