import React from 'react';
import { SelectOption } from '../../BaseSelect/BaseSelect';
import { BaseSelectModal } from '../../BaseSelect/BaseSelectModal/BaseSelectModal';
import { ButtonContentDiv } from '../../BaseSelect/SelectButton/SelectButton.styled';

interface MultiSelectModalProps<T extends object> {
  options: SelectOption<T>[];
  clickedOnOption: (option: SelectOption<T>) => void;
}

export function MultiSelectModal<T extends object>(
  props: MultiSelectModalProps<T>
) {
  const buttonContent = (option: SelectOption<T>) => {
    return <ButtonContentDiv>{option.displayedValue}</ButtonContentDiv>;
  };

  return (
    <BaseSelectModal
      options={props.options}
      onClick={props.clickedOnOption}
      buttonContent={buttonContent}
      multi
    />
  );
}
