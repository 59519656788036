import {
  NoOptionsDiv,
  StyledBaseSelectModal,
  StyledInnerDiv,
} from './BaseSelectModal.styled';
import { SelectOption, useBaseSelectContext } from '../BaseSelect';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { SelectButton } from '../SelectButton/SelectButton';

export interface BaseSelectModalProps<T extends object> {
  options: SelectOption<T>[];
  onClick: (option: SelectOption<T>) => void;
  buttonContent: (option: SelectOption<T>) => any;
  multi?: boolean;
  extraSection?: ReactElement;
  disableOption?: (value: T) => boolean;
}

const modalRowHeight = 40;
export const modalMaxHeight = 280;

export function BaseSelectModal<T extends object>(
  props: BaseSelectModalProps<T>
) {
  const { buttonRefs, hoverIndex } = useBaseSelectContext();

  const elementRef = useRef(null);
  const [modalHeight, setModalHeight] = useState<string>('0px');
  const [extraSectionHeight, setExtraSectionHeight] = useState<number>(0);

  const innerDivRef = useRef<HTMLDivElement>(null);
  const [isScrollBarVisible, setIsScrollBarVisible] = useState<boolean>(false);

  useEffect(() => {
    let completeHeight =
      props.options.length > 0
        ? props.options.length * modalRowHeight
        : modalRowHeight;
    if (elementRef.current) {
      const elementHeight = (elementRef.current as any).offsetHeight;
      setExtraSectionHeight(elementHeight);
      completeHeight += elementHeight;
    }
    setModalHeight(`${completeHeight}px`);

    if (innerDivRef.current) {
      setIsScrollBarVisible(completeHeight > modalMaxHeight);
    }
  }, [props]);

  return (
    <StyledBaseSelectModal
      modalHeight={modalHeight}
      maxHeight={`${modalMaxHeight + extraSectionHeight}px`}
      tabIndex={-1}
    >
      <StyledInnerDiv
        ref={innerDivRef}
        extraSectionHeight={`${extraSectionHeight}px`}
      >
        {props.options.length > 0 ? (
          props.options.map((option: SelectOption<T>, index: number) => {
            return (
              <SelectButton
                option={option}
                isHoveringOver={hoverIndex === index}
                index={index}
                onClick={() => props.onClick(option)}
                multi={props.multi}
                buttonRefs={buttonRefs}
                scrollBarVisible={isScrollBarVisible}
                disabled={
                  props.disableOption
                    ? props.disableOption(option.option)
                    : false
                }
              >
                {props.buttonContent(option)}
              </SelectButton>
            );
          })
        ) : (
          <NoOptionsDiv>No options</NoOptionsDiv>
        )}
      </StyledInnerDiv>
      <div ref={elementRef}>{props.extraSection}</div>
    </StyledBaseSelectModal>
  );
}
