import React, { useRef } from 'react';
import styled from 'styled-components';
interface ModalTypes {
  open: boolean;
  closeModal: () => void;
  headerText: any;
  children: any;
}

export function Modal(props: ModalTypes) {
  const handleModalClick = (event: React.MouseEvent<HTMLElement>) => {
    // This will prevent the click inside the modal from triggering the button click
    event.stopPropagation();
  };

  const menuRef = useRef<HTMLDivElement | null>(null);

  return (
    <StyledModalBackground onClick={handleModalClick} open={props.open}>
      <StyledModal open={props.open} ref={menuRef}>
        <HeaderDiv>{props.headerText}</HeaderDiv>
        {props.children}
      </StyledModal>
    </StyledModalBackground>
  );
}

const StyledModalBackground = styled.div<{ open?: boolean }>`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  position: fixed;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  display: ${(props) => (props.open ? 'flex' : 'none')};
  z-index: 1000;
  cursor: default;
`;

const StyledModal = styled.div<{
  open?: boolean;
}>`
  width: calc(500px - 40px);
  height: calc(200px - 40px);
  padding: 20px;
  background-color: white;
  box-shadow: inset 0 0 0 1px #8c8c8c;
  border-radius: 8px;
  display: ${(props) => (props.open ? 'block' : 'none')};
  position: absolute;
  z-index: 1001;
`;

const HeaderDiv = styled.div`
  width: 100%;
  height: 40px;
  font-weight: 700;
  font-size: 22px;
  color: var(--web-black);
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  z-index: 1001;
`;

export const StyledButton = styled.button`
  width: 80px;
  height: 40px;
  border: 0;
  font-size: 15px;
  font-weight: 700;
  border-radius: 8px;
  overflow: hidden;
  outline: none;
  padding: 0;
  cursor: pointer;
  margin: 0;
  z-index: 1001;
  &:hover {
    transform: none;
  }
`;
