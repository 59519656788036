import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showErrorPopup = (message: string) => {
  toast.error(message, {
    position: "top-center",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
};