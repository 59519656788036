import React, { useEffect, useState, useMemo, useRef } from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import { BarChart } from '@mui/x-charts/BarChart';
import { GraphData as GraphDataType, GraphProps } from './GraphType';
import { ToggleButtons } from '../../ToggleButtons/ToggleButton';
import { addDays, isAfter, isBefore, startOfWeek } from 'date-fns';
import {
  changeDateToWeek,
  fromStringToDate,
  weekStringToDate,
} from '../Functions/TimeFunctions';
import { ReactComponent as CopyIcon } from '../../../assets/Copy.svg';
import { IconButton } from '../../IconButton/IconButton';
import { RowEntry } from '../PlanningType';
import { getMaxPlannedDate } from '../PlanningTable/Columns';
import { styled } from 'styled-components';
import { formatCurrencyFields } from '../Functions/GridFunctions';
import { StyledButton } from '../../Modals/Modal';
import { MultiSelect } from '../../Select/MultiSelect/MultiSelect';
import { PhaseCodeEntry } from '../../../App.types.';

export function Graph(props: GraphProps) {
  const chartRef = useRef<SVGElement | null>(null); // Create a ref specifically for SVG elements
  const [graphData, setGraphData] = useState<GraphDataType | null>(null);
  const [totalGraph, setTotalGraph] = useState<boolean>(true);
  const [selectedPhases, setSelectedPhases] = useState<PhaseCodeEntry>({});

  // Retrieve the computed value of the CSS variable because some browsers do not resolve it when converting the SVG into an image
  const resolveCSSVariable = (variable: string) => {
    return (
      getComputedStyle(document.documentElement).getPropertyValue(variable) ||
      variable
    );
  };

  // Map PhaseCodeEntry to { value: phaseCode, label: phaseName } format
  const phasesArray = useMemo(() => {
    return [
      ...Object.entries(props.projectData.phases)
        .sort((a, b) => (a[1].toUpperCase() < b[1].toUpperCase() ? -1 : 1))
        .map(([key, value]) => ({
          value: key,
          label: value,
        })),
    ];
  }, [props.projectData.phases]);

  // Function to handle the selection of phases, including "All parts"
  const handlePhaseChange = (
    value: { value: string; label: string } | null,
    isSelected: boolean
  ) => {
    if (!value) {
      setSelectedPhases({});
      return;
    }
    if (isSelected) {
      const updatedPhase = { [value.value]: value.label };
      setSelectedPhases((prev) => {
        return { ...prev, ...updatedPhase };
      });
    } else {
      setSelectedPhases((prev) => {
        const { [value.value]: _, ...newData } = prev;
        return newData;
      });
    }
  };

  const handleToggleChange = () => {
    // Toggle the value of props.totalGraph to switch between Weekly/Total graph when clicking the button
    setTotalGraph(!totalGraph);
  };

  // Function to copy the chart image to the clipboard
  const copyGraphImage = async () => {
    if (chartRef.current) {
      const svgElement = chartRef.current;

      // Apply graph styling to HTML elements before copying as image
      applyGraphStyling(svgElement, totalGraph, graphData);

      // Convert the canvas to image data and copy to clipboard
      const canvas = await svgToCanvas(svgElement);
      canvas.toBlob(async (blob) => {
        if (blob) {
          await navigator.clipboard.write([
            new ClipboardItem({ [blob.type]: blob }),
          ]);
        }
      }, 'image/png');
    }
  };

  // Function to convert SVG to Canvas
  const svgToCanvas = (svgElement: SVGElement): Promise<HTMLCanvasElement> => {
    return new Promise((resolve, reject) => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      if (!ctx) return reject('Canvas rendering context not available.');

      // Clone and serialize SVG
      const clonedSvg = svgElement.cloneNode(true) as SVGElement;
      const svgData = new XMLSerializer().serializeToString(clonedSvg);

      // Create an image to load the SVG data
      const img = new Image();

      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        resolve(canvas);
      };

      img.onerror = reject;
      img.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgData)}`;
    });
  };

  // Function to dynamically add graph styling to HTML elements
  const applyGraphStyling = (
    chartElement: SVGElement,
    totalGraph: boolean,
    graphData: any
  ) => {
    // Get the actual RGB value of CSS variable colors
    const resolvedColors = {
      navy: resolveCSSVariable('--twd-navy'),
      aqua: resolveCSSVariable('--twd-aqua'),
      sunglow: resolveCSSVariable('--sunglow'),
    };

    // Apply colors to graph series
    if (totalGraph) {
      const seriesColors = [
        resolvedColors.aqua,
        resolvedColors.navy,
        resolvedColors.sunglow,
      ]; // Line colors
      const lines = chartElement.querySelectorAll('path'); // Get all lines (path elements)
      lines.forEach((line, index) => {
        const seriesIndex = line.getAttribute('class')?.match(/id-(\d+)/)?.[1]; // Extract the series id (id-0 for budget, id-1 for spent, id-2 for planned)
        if (seriesIndex) {
          const color = seriesColors[parseInt(seriesIndex, 10)]; // Get the color based on the series id
          line.setAttribute('stroke', color); // Set the stroke color for the line
          line.setAttribute('fill', 'none'); // Ensure no fill is applied to the line
          line.setAttribute('stroke-width', '2');
        }
      });
    } else {
      const seriesColors = [resolvedColors.navy, resolvedColors.sunglow]; // Bar colors
      const bars = chartElement.querySelectorAll('rect'); // Get all bars (rect elements)
      bars.forEach((bar, index) => {
        const seriesIndex = bar.getAttribute('class')?.match(/id-(\d+)/)?.[1]; // Extract the series id (id-0 for spent, id-1 for planned)
        if (seriesIndex) {
          const color = seriesColors[parseInt(seriesIndex, 10)]; // Get the color based on the series id
          bar.setAttribute('fill', color); // Set the color for the bar
        }
      });
    }
    // Apply grid line styles
    chartElement.querySelectorAll('.MuiChartsGrid-line').forEach((line) => {
      line.setAttribute('stroke', 'rgba(0, 0, 0, 0.12)');
      line.setAttribute('stroke-width', '1');
    });
    // Apply axis label styles
    chartElement.querySelectorAll('.MuiChartsAxis-label').forEach((label) => {
      label.setAttribute('font-family', 'Arial');
    });
    chartElement
      .querySelectorAll('.MuiChartsAxis-tickLabel')
      .forEach((text) => {
        text.setAttribute('font-family', 'Arial');
      });
    // Apply axis line styles
    chartElement.querySelectorAll('.MuiChartsAxis-line').forEach((line) => {
      line.setAttribute('stroke', 'rgba(0, 0, 0, 0.87)');
      line.setAttribute('stroke-width', '1');
    });
    // Add axis ticks
    chartElement.querySelectorAll('.MuiChartsAxis-tick').forEach((tick) => {
      tick.setAttribute('stroke', 'rgba(0, 0, 0, 0.87)');
    });
  };

  useEffect(() => {
    // Loop through the entries in weekData and filter on selected phases
    const filteredData: {
      [weekString: string]: { actualCosting: number; costEstimate: number };
    } = {};
    props.weekData.forEach((value, [phaseCode, weekString]) => {
      if (phaseCode in selectedPhases) {
        if (!filteredData[weekString]) {
          filteredData[weekString] = {
            actualCosting: value.actualCosting,
            costEstimate: value.costEstimate,
          };
        } else {
          filteredData[weekString].actualCosting += value.actualCosting;
          filteredData[weekString].costEstimate += value.costEstimate;
        }
      }
    });

    const spentList: number[] = [];
    const cumulativeSpentList: number[] = [];
    let cumulativeSpent = 0;

    const cumulativePlannedList: (number | null)[] = [];
    let cumulativePlanned = 0;

    const weeks: string[] = [];
    const plannedList: number[] = [];
    let beginDate = new Date(props.projectData.startDate);
    beginDate = startOfWeek(beginDate, { weekStartsOn: 1 });

    const cumulativeBudgets = [];
    let cumulativeBudget = Object.entries(filteredData).reduce(
      (sum, [key, acc]) => {
        if (isBefore(weekStringToDate(key), beginDate)) {
          sum += acc.costEstimate;
        }
        return sum;
      },
      0
    );

    // Recalculate graph series when planning or selected phases change
    if (
      props.updatedRows.length > 0 ||
      graphData?.selectedPhases !== selectedPhases
    ) {
      const currentRows = props.updatedRows;
      const maxPlannedDate = getMaxPlannedDate(currentRows);
      while (
        isBefore(beginDate, maxPlannedDate) ||
        beginDate === maxPlannedDate
      ) {
        const weekString = changeDateToWeek(beginDate);
        weeks.push(weekString);

        const thisWeekString = changeDateToWeek(new Date());
        const thisWeekDate = weekStringToDate(thisWeekString);
        const budget = filteredData[weekString]?.costEstimate || 0;
        cumulativeBudget += budget;
        cumulativeBudgets.push(cumulativeBudget);
        const spent = filteredData[weekString]?.actualCosting || 0;
        spentList.push(spent);
        const planned = props.updatedRows.reduce((sum, row: RowEntry) => {
          const {
            id,
            part,
            partId,
            color,
            employee,
            task,
            rate,
            spent,
            ...columnEntries
          } = row;
          // Check if the phase row.partId is selected
          if (partId in selectedPhases) {
            const thisWeekList = Object.entries(columnEntries).filter(
              ([key, _]) => {
                const keyDate = fromStringToDate(key);
                const keyString = changeDateToWeek(keyDate);
                return keyString === weekString;
              }
            );
            sum += thisWeekList.reduce((innerSum, week) => {
              innerSum += week[1] * (rate ? props.projectData.rates[rate] : 1);
              return innerSum;
            }, 0);
          }
          return sum;
        }, 0);
        plannedList.push(planned);

        if (weekString === thisWeekString) {
          cumulativePlanned += planned;
          cumulativePlannedList.push(cumulativePlanned);
        }
        if (isBefore(beginDate, thisWeekDate)) {
          cumulativeSpent += spent;
          cumulativeSpentList.push(cumulativeSpent);

          const oneWeekAgo = thisWeekDate;
          oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
          if (weekString === changeDateToWeek(oneWeekAgo)) {
            cumulativePlanned += cumulativeSpent;
            cumulativePlannedList.push(cumulativePlanned);
          } else {
            cumulativePlannedList.push(null);
          }
        }
        if (isAfter(beginDate, thisWeekDate)) {
          cumulativePlanned += planned;
          cumulativePlannedList.push(cumulativePlanned);
        }
        const weekStart = startOfWeek(beginDate, { weekStartsOn: 1 });
        beginDate = addDays(weekStart, 7);
      }

      const graphData = {
        weeks: weeks,
        spent: spentList,
        plannedList: plannedList,
        cumulativeSpent: cumulativeSpentList,
        cumulativeBudget: cumulativeBudgets,
        cumulativePlannedList: cumulativePlannedList,
        selectedPhases: selectedPhases,
      };

      // Update props data to show in the graph
      setGraphData(graphData);
    } else {
      while (isBefore(beginDate, new Date())) {
        const weekString = changeDateToWeek(beginDate);
        weeks.push(weekString);
        const budget = filteredData[weekString]?.costEstimate || 0;
        cumulativeBudget += budget;
        cumulativeBudgets.push(cumulativeBudget);

        const spent = filteredData[weekString]?.actualCosting || 0;
        spentList.push(spent);
        cumulativeSpent += spent;
        cumulativeSpentList.push(cumulativeSpent);

        cumulativePlannedList.push(null);
        const weekStart = startOfWeek(beginDate, { weekStartsOn: 1 });
        beginDate = addDays(weekStart, 7);
      }

      cumulativePlannedList[cumulativePlannedList.length - 1] = cumulativeSpent;

      // Update props data to show in the graph
      setGraphData({
        weeks: weeks,
        spent: spentList,
        plannedList: [],
        cumulativeSpent: cumulativeSpentList,
        cumulativeBudget: cumulativeBudgets,
        cumulativePlannedList: cumulativePlannedList,
        selectedPhases: selectedPhases,
      });
    }
  }, [props.updatedRows, props.projectData, props.weekData, selectedPhases]);

  return (
    <GraphDiv>
      <div
        style={{ position: 'absolute', top: '20px', right: '20px', zIndex: 1 }}
      >
        <ToggleButtons
          firstButtonText={'Total'}
          secondButtonText={'Weekly'}
          value={totalGraph}
          onChange={handleToggleChange}
        />
      </div>
      <SelectionPartDiv>
        <MultiSelect
          options={phasesArray}
          optionLabels={(option: { value: string; label: string }) =>
            option.label
          }
          onChange={(
            value: { value: string; label: string } | null,
            isSelected: boolean
          ) => handlePhaseChange(value, isSelected)}
          itemType={'parts'}
          autoSelectAll
          placeholder={'Select Parts'}
          disabled={props.projectData.projectId === ''}
        />
      </SelectionPartDiv>
      {totalGraph ? (
        // If totalGraph is true, meaning that the selected button value is Total, show total line chart
        <LineChart
          // Ref used to copy the graph as image
          ref={chartRef}
          margin={{ left: 100, right: 100 }}
          xAxis={[
            {
              label: 'Year - Week',
              data: graphData?.weeks || [],
              scaleType: 'point',
            },
          ]}
          yAxis={[
            {
              valueFormatter: (value, _) => {
                return formatCurrencyFields(props.projectData.currency, value);
              },
            },
          ]}
          series={[
            {
              label: 'Budget',
              data: graphData?.cumulativeBudget || [],
              type: 'line',
              connectNulls: true, // Connect data even if not all weeks have budget
              showMark: false, // Do not show point markers
              color: resolveCSSVariable('--twd-aqua'), // Actual RGB value of CSS variable color for "Budget
            },
            {
              label: 'Spent',
              data: graphData?.cumulativeSpent || [],
              type: 'line',
              connectNulls: true, // Connect data even if not all weeks have spent
              showMark: false, // Do not show point markers
              color: resolveCSSVariable('--twd-navy'), // Actual RGB value of CSS variable color for "Spent"
            },
            {
              label: 'Planned',
              data: graphData?.cumulativePlannedList || [],
              type: 'line',
              connectNulls: true, // Connect data even if not all weeks have planned
              showMark: false, // Do not show point markers
              color: resolveCSSVariable('--sunglow'), // Actual RGB value of CSS variable color for "Planned"
            },
          ]}
          grid={{ vertical: true, horizontal: true }}
        />
      ) : (
        // Else, meaning that the selected button value is Weekly, show weekly bar chart
        <BarChart
          // Ref used to copy the graph as image
          ref={chartRef}
          margin={{ left: 100, right: 100 }}
          xAxis={[
            {
              label: 'Year - Week',
              data: graphData?.weeks || [],
              scaleType: 'band', // Adding scaleType for time-like data
            },
          ]}
          yAxis={[
            {
              valueFormatter: (value, _) => {
                return formatCurrencyFields(props.projectData.currency, value);
              },
            },
          ]}
          series={[
            {
              label: 'Spent',
              data: graphData?.spent || [],
              color: resolveCSSVariable('--twd-navy'), // Actual RGB value of CSS variable color for "Spent"
            },
            {
              label: 'Planned',
              data: graphData?.plannedList || [],
              color: resolveCSSVariable('--sunglow'), // Actual RGB value of CSS variable color for "Planned"
            },
          ]}
          grid={{ vertical: true, horizontal: true }}
        />
      )}
      {/* Button to copy the graph as image to the clipboard */}
      <IconButtonDiv>
        <IconButton hoverColor={'var(--twd-aqua)'} onClick={copyGraphImage}>
          <CopyIcon />
        </IconButton>
      </IconButtonDiv>
    </GraphDiv>
  );
}

const GraphDiv = styled.div`
  width: calc(50% - 45px);
  padding: 20px;
  padding-bottom: 45px;
  margin-left: 5px;
  position: relative;
  background-color: white;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px var(--grey-pale3);

  @media (max-width: 1250px) {
    width: calc(100% - 40px);
    margin-bottom: 10px;
    margin-left: 0;
  }
`;

const SelectionPartDiv = styled.div<{}>`
  width: calc(50% - 5px);
  margin-right: 5px;
  height: 40px;
  background: white;
`;

const IconButtonDiv = styled.div`
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  z-index: 11;
`;

const CopyButton = styled(StyledButton)`
  width: 40px;
  height: 30px;
  background-color: white;
  box-shadow: inset 0 0 0 1.5px var(--grey-pale3);
  color: var(--grey-pale3);
  margin-right: 0px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px;
  &:hover {
    transform: none;
    background-color: var(--twd-grey);
    box-shadow: inset 0 0 0 1.5px var(--twd-grey);
  }
`;
