import React, { ChangeEvent, Dispatch, SetStateAction, useRef } from 'react';
import {
  SelectBoxDiv,
  StyledPlaceHolder,
  SelectBoxArrowDiv,
  SelectBoxArrow,
  StyledSelectInputValue,
  StyledSelectValue,
} from './BaseSelectBox.styled';

export type BoxType = 'text' | 'input';

interface BaseSelectBoxProps {
  boxType: BoxType;
  displayedText: string;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  flickering?: boolean;
}

export function BaseSelectBox(props: BaseSelectBoxProps) {
  const ref = useRef(null);

  const onDivClick = () => {
    if (!props.disabled) {
      props.setOpenModal(true);
      if (ref.current) {
        (ref.current as any).focus();
      }
    }
  };

  const handleKeyDown = (event: any) => {
    if (event.key === 'ArrowDown' || event.key === 'ArrowUp') {
      event.preventDefault();
    }
  };

  return (
    <SelectBoxDiv
      isInputFieldSelected={props.openModal}
      onClick={onDivClick}
      onFocus={(event: any) => event.target.select()}
      disabled={props.disabled}
      boxType={props.boxType}
      flickering={props.flickering}
    >
      {props.boxType === 'text' ? (
        <StyledSelectValue isInputFieldSelected={props.openModal}>
          {props.displayedText}
        </StyledSelectValue>
      ) : (
        <StyledSelectInputValue
          value={props.displayedText}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            props.onChange && props.onChange(e.target.value)
          }
          isInputFieldSelected={props.openModal}
          onKeyDown={handleKeyDown}
          ref={ref}
          disabled={props.disabled}
        />
      )}
      <StyledPlaceHolder
        className={'MultiSelectPlaceholder'}
        isInputFieldSelected={props.openModal}
        inputValueNotEmpty={!props.disabled && props.displayedText !== ''}
        flickering={props.flickering}
      >
        {props.placeholder ? props.placeholder : ''}
      </StyledPlaceHolder>
      <SelectBoxArrowDiv>
        <SelectBoxArrow
          isInputFieldSelected={props.openModal}
          disabled={props.disabled}
        />
      </SelectBoxArrowDiv>
    </SelectBoxDiv>
  );
}
