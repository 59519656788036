import React, { Dispatch, SetStateAction } from 'react';
import { BaseSelectBox } from '../../BaseSelect/BaseSelectBox/BaseSelectBox';

interface AutoCompleteBoxProps<T extends object> {
  inputValue: string;
  onValueChange: (value: string) => void;
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  placeholder?: string;
  disabled?: boolean;
  flickering?: boolean;
}

export function AutoCompleteBox<T extends object>(
  props: AutoCompleteBoxProps<T>
) {
  return (
    <BaseSelectBox
      boxType={'input'}
      displayedText={props.inputValue}
      openModal={props.openModal}
      setOpenModal={props.setOpenModal}
      placeholder={props.placeholder}
      disabled={props.disabled}
      onChange={props.onValueChange}
      flickering={props.flickering}
    />
  );
}
