import React, {
  Dispatch,
  memo,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { usePlanningUpdates } from '../Planning.hooks';
import { getBaseColumns } from './Columns';
import {
  DataGridPremium,
  GRID_AGGREGATION_FUNCTIONS,
  GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
  GridAggregationFunction,
  GridApiPro,
  GridCellParams,
  GridColDef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { ColumnValue } from '../Functions/GridFunctions';
import { PlanningSpent } from '../../../Settings';
import {
  PhaseData,
  PlanningData,
  PlanningType,
  RowEntry,
} from '../PlanningType';
import { EditToolbar } from '../Toolbar/Toolbar';
import { CustomColumnMenu, CustomColumnMenuIcon } from './CustomColumnMenu';
import { ProjectType } from '../../../App.types.';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';

interface DataGridProps {
  projectData: ProjectType;
  planningData: PlanningData;
  setUpdatedRows: Dispatch<SetStateAction<RowEntry[]>>;
  phaseData: PhaseData;
  selectedPlanning: PlanningType | null;
  setSelectedPlanning: (selectedPlanning: PlanningType | null) => void;
  inEditMode: boolean;
  setIsloading: (p: boolean) => void;
  allPlannings: PlanningType[];
  setAllPlannings: (p: PlanningType[]) => void;
  alreadyInUse: string;
  setChangeIsMade: Dispatch<SetStateAction<boolean>>;
  setFlickerEditButton: Dispatch<SetStateAction<boolean>>;
}

// Columns that only require clicking once to open the cell.
const singleClickColumns = ['color', 'rate'];

export const PlanningTable = memo(function DataGrid(props: DataGridProps) {
  const {
    apiRef,
    colorRef,
    addRow,
    deleteRow,
    copyRow,
    addColumn,
    changePlanningSettings,
    changeRowColor,
  } = usePlanningUpdates(
    props.projectData,
    props.planningData,
    props.setUpdatedRows,
    props.setChangeIsMade
  );

  const [baseColumns, setBaseColumns] = useState<GridColDef<RowEntry>[]>([]);
  const [hourView, setHourView] = useState<boolean>(false);

  useEffect(() => {
    setBaseColumns(
      getBaseColumns(
        addRow,
        deleteRow,
        copyRow,
        addColumn,
        changeRowColor,
        colorRef,
        props.projectData,
        props.phaseData,
        props.inEditMode,
        hourView
      )
    );
  }, [props.selectedPlanning, props.phaseData, props.inEditMode, hourView]);

  const valueWeekAggregation: GridAggregationFunction<ColumnValue, string> = {
    label: '',
    apply: (params) => {
      if (!params.values) {
        return '';
      }
      const SpentAddedPerPhase: string[] = [];
      let valueSum: number = params.values.reduce((sum: number, value) => {
        // Get hours spent (value) if hourView, else calculate amount spent (value*rate)
        let newValue = hourView
          ? value?.value || 0
          : (value?.value || 0) * (value?.rate || 0);
        if (
          value?.spent &&
          value?.spent > 0 &&
          !SpentAddedPerPhase.includes(value?.partId)
        ) {
          // Add hour spent if hourView, else amount spent
          newValue += hourView ? value?.hourSpent : value?.spent;
          SpentAddedPerPhase.push(value?.partId);
        }
        return (sum || 0) + (newValue || 0);
      }, 0);
      // Call setAggregatedPlanned to store planned per week or per phase in Graph props
      // setAggregatedPlanned(params.field, String(params.groupId), valueSum);
      if (valueSum === 0) return '0';
      return valueSum?.toLocaleString('nl-NL', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    // Format with currency symbol if not hourView
    valueFormatter: (value) =>
      hourView ? value : props.projectData.currency + ' ' + value,
    columnTypes: ['number'],
  };

  const handleCellClick = React.useCallback(
    (params: GridCellParams, event: React.MouseEvent) => {
      if (!params.isEditable) {
        return;
      }

      if (apiRef.current) {
        // Ignore portal (not completely sure, this came from https://mui.com/x/react-data-grid/recipes-editing/#single-click-editing)
        if (
          (event.target as any).nodeType === 1 &&
          !event.currentTarget.contains(event.target as Element)
        ) {
          return;
        }
        if (
          singleClickColumns.includes(params.field) &&
          apiRef.current.getCellMode(params.id, params.field) === 'view'
        ) {
          apiRef.current.startCellEditMode({
            id: params.id,
            field: params.field,
          });
        }
      }
    },
    []
  );

  const initialState = useKeepGroupedColumnsHidden({
    apiRef: apiRef as MutableRefObject<GridApiPro>,
    initialState: {
      rowGrouping: {
        model: ['part'],
      },
      pinnedColumns: {
        left: [
          GRID_ROW_GROUPING_SINGLE_GROUPING_FIELD,
          'color',
          'task',
          'actions',
          'rate',
          'employee',
          `${PlanningSpent}`,
        ],
        right: ['new-week', 'planned', 'total'],
      },
    },
  });
  return (
    <>
      <EditToolbar
        apiRef={apiRef}
        projectData={props.projectData}
        allPlannings={props.allPlannings}
        setAllPlannings={props.setAllPlannings}
        planningId={props.selectedPlanning}
        setPlanningId={props.setSelectedPlanning}
        setIsloading={props.setIsloading}
        inEditMode={props.inEditMode}
        alreadyInUse={props.alreadyInUse}
        changePlanningSettings={changePlanningSettings}
        hourView={hourView}
        setHourView={setHourView}
      />
      <DataGridPremium
        sx={TableStyling}
        apiRef={apiRef as MutableRefObject<GridApiPremium>}
        initialState={initialState}
        rows={props.planningData.rows}
        rowHeight={30}
        columnHeaderHeight={30}
        columns={[...baseColumns, ...props.planningData.weekColumns]}
        disableColumnFilter
        cellSelection
        rowSelection={false}
        hideFooter={true}
        onCellClick={handleCellClick}
        processRowUpdate={(newRow, oldRow) => {
          props.setUpdatedRows((oldUpdatedRows: RowEntry[]) =>
            oldUpdatedRows.map((oldUpdatedRow: RowEntry) => {
              if (oldUpdatedRow.id === newRow.id) {
                return newRow; // Replace the row with the updated row
              }
              return oldUpdatedRow; // Return the original row if it doesn't match
            })
          );
          props.setChangeIsMade(true);
          return newRow;
        }}
        onCellDoubleClick={() => {
          if (!props.inEditMode) props.setFlickerEditButton(true);
        }}
        aggregationFunctions={{
          ...GRID_AGGREGATION_FUNCTIONS,
          valueWeek: valueWeekAggregation,
        }}
        groupingColDef={{
          hideDescendantCount: true, // Hide the number next to the group
        }}
        slots={{
          columnMenu: CustomColumnMenu,
          columnMenuIcon: CustomColumnMenuIcon,
        }}
      />
    </>
  );
});

export const TableStyling = {
  border: 0,
  '& .MuiDataGrid-main': {
    border: 'solid 1px var(--grey-pale3)',
  },
  '& .MuiDataGrid-cell': { padding: '0 5px' },
  '& .MuiInputBase-input': { padding: '0 5px' },
  '& .MuiDataGrid-columnHeader': {
    background: 'var(--navy-pale2)',
  },
  '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-columnHeaders .MuiDataGrid-filler': {
    background: 'var(--navy-pale2)',
  },
  '.MuiDataGrid-footerCell': {
    color: 'var(--twd-navy)',
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-columnHeaders .MuiDataGrid-columnSeparator': {
    color: 'white',
  },
  '& .MuiDataGrid-scrollbar': {
    zIndex: 1,
  },
  '& .MuiDataGrid-columnHeaders .MuiDataGrid-scrollbarFiller': {
    background: 'var(--twd-aqua)',
  },
  '& .MuiDataGrid-aggregationColumnHeaderLabel': {
    display: 'none',
  },
  '& .MuiDataGrid-cell--pinnedLeft': {
    padding: '0',
  },
  '& .MuiDataGrid-actionsCell': {
    width: '100%',
    height: '100%',
  },
};
