import { styled } from 'styled-components';

export const StyledBaseSelectModal = styled.div<{
  modalHeight: string;
  maxHeight: string;
}>`
  width: calc(100% - 20px);
  height: ${(props) => props.modalHeight};
  max-height: ${(props) => props.maxHeight};
  border-radius: 0 0 8px 8px;
  border: 1.5px solid var(--grey-pale3);
  border-top: none;
  background: white;
  padding: 8px;
  position: relative;
  z-index: 3;
  outline: 0;

  box-shadow:
    rgba(140, 140, 140, 0.35) 0px 3px 5px,
    rgba(140, 140, 140, 0.25) 2px 4px 8px;
`;

export const StyledInnerDiv = styled.div<{
  extraSectionHeight: string;
}>`
  width: 100%;
  height: ${(props) => `calc(100% - ${props.extraSectionHeight})`};
  min-height: 40px;
  overflow: auto;
  position: relative;
  border-radius: 8px;
  z-index: 3;
  outline: 0;
`;

export const NoOptionsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
