import { useEffect, useRef, useState } from 'react';
import { SelectOption } from './BaseSelect/BaseSelect';

export const useChangeToSelectOption = <T extends object>(
  options: T[],
  optionLabels: (option: T) => string,
  onChange: (option: SelectOption<T>, isSelected: boolean) => void,
  autoSelectIndex?: number,
  multi?: boolean
) => {
  const [selectOptions, setSelectOptions] = useState<SelectOption<T>[]>([]);

  const selectAllOption: SelectOption<T> = {
    id: -1,
    option: {} as T,
    displayedValue: 'Select all',
    isSelected: false,
  };

  useEffect(() => {
    const isAllAutoSelected =
      multi && autoSelectIndex !== undefined && autoSelectIndex === 0;

    const newBaseOptions: SelectOption<T>[] = options.map(
      (option: T, index: number) => {
        return {
          id: index,
          option: option,
          displayedValue: optionLabels(option),
          isSelected: false,
        };
      }
    );
    let newOptions = newBaseOptions;
    if (multi) newOptions = [selectAllOption, ...newOptions];
    setSelectOptions(newOptions);
    if (newOptions.length > 0 && autoSelectIndex !== undefined) {
      if (isAllAutoSelected) {
        clickOnOption(selectAllOption);
      } else {
        clickOnOption(newOptions[autoSelectIndex]);
      }
    }
  }, [options, autoSelectIndex]);

  const clickOnOption = (clickedOption: SelectOption<T>) => {
    setSelectOptions((prev) => {
      return prev.map((o: SelectOption<T>) => {
        if (multi) {
          if (clickedOption.id === -1) {
            onChange(o, !clickedOption.isSelected);
            return { ...o, isSelected: !clickedOption.isSelected };
          }
          if (o.id === -1) return { ...o, isSelected: false };
          if (clickedOption.id === o.id)
            return { ...o, isSelected: !clickedOption.isSelected };
          return o;
        } else {
          return { ...o, isSelected: clickedOption.id === o.id };
        }
      });
    });
    if (clickedOption.id !== -1) {
      onChange(clickedOption, !clickedOption.isSelected);
    }
  };

  return { selectOptions, setSelectOptions, clickOnOption };
};

export const useOutsideClick = (
  callback: () => void,
  setHoverIndex: (hoverIndex: number) => void
) => {
  const ref = useRef<any>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
        setHoverIndex(-1);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [callback]);

  return ref;
};

export const useKeyControl = <T extends object>(
  options: SelectOption<T>[],
  onClick: (option: SelectOption<T>) => void
) => {
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const [hoverIndex, setHoverIndex] = useState<number>(-1);

  const handleKeyDown = (event: any) => {
    let newIndex = hoverIndex;
    if (event.key === 'ArrowDown') {
      if (newIndex < options.length) {
        newIndex += 1;
        setHoverIndex(newIndex);
        const hoveredButton = buttonRefs.current[newIndex];
        if (hoveredButton) {
          hoveredButton.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }
      }
    } else if (event.key === 'ArrowUp') {
      if (newIndex > -1) {
        newIndex -= 1;
        setHoverIndex(newIndex);
        const hoveredButton = buttonRefs.current[newIndex];
        if (hoveredButton) {
          hoveredButton.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest',
            inline: 'center',
          });
        }
      }
    } else if (event.key === 'Enter') {
      if (newIndex !== -1) onClick(options[newIndex]);
    }
  };

  return { buttonRefs, hoverIndex, setHoverIndex, handleKeyDown };
};
