import { styled } from 'styled-components';
import { BoxType } from './BaseSelectBox';

export const SelectBoxDiv = styled.div<{
  boxType: BoxType;
  isInputFieldSelected?: boolean;
  disabled?: boolean;
  flickering?: boolean;
}>`
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  position: relative;
  display: flex;
  background: white;
  color: var(--web-black);

  border: 1.5px solid var(--grey-pale3);
  border-bottom: 1.5px solid var(--grey-pale3);
  border-radius: 8px;
  cursor: ${(props) => (props.boxType === 'text' ? 'pointer' : 'text')};
  z-index: 1;
  animation: ${(props) =>
    props.flickering && 'flickerButtonAnimation 1s linear infinite'};

  @keyframes flickerButtonAnimation {
    0%,
    100% {
      border-color: var(--grey-pale3);
    }
    50% {
      border-color: var(--twd-aqua);
    }
  }

  ${({ isInputFieldSelected }) =>
    isInputFieldSelected &&
    `
     border-radius: 8px 8px 0 0;
     border-bottom: 1px solid var(--grey-pale3);
     box-shadow: rgba(140, 140, 140, 0.35) 0px 3px 5px, rgba(140, 140, 140, 0.25) 2px 4px 8px
  `}

  ${({ disabled }) =>
    disabled &&
    `
    color: var(--grey-pale3);
    cursor: initial;
    box-shadow: none;
    
    &:hover {
      box-shadow: none;
    }
  `}
`;

export const StyledSelectValue = styled.div<{
  isInputFieldSelected?: boolean;
}>`
  position: relative;
  width: calc(100% - 46px);
  height: calc(100% - 4px);
  margin: 2px;
  border: 0;
  outline: none;
  font: inherit;
  background: inherit;
  border-radius: 8px;
  padding: 0 0 0 10px;
  color: inherit;
  display: flex;
  align-items: center;
  z-index: 1;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Clips the overflowing text */
  user-select: none;
`;

export const StyledSelectInputValue = styled(StyledSelectValue).attrs({
  as: 'input',
})`
  cursor: ${(props) => (props.disabled ? 'initial' : 'text')};
`;

export const StyledPlaceHolder = styled.span<{
  isInputFieldSelected?: boolean;
  inputValueNotEmpty?: boolean;
  flickering?: boolean;
}>`
  font: inherit;
  position: absolute;
  pointer-events: none;
  background: inherit;
  left: 0;
  top: 0;
  width: calc(100% - 46px);
  height: calc(100% - 4px);
  margin: 2px;
  padding: 0 0 0 10px;
  z-index: 2;
  display: flex;
  align-items: center;
  user-select: none;

  ${({ flickering }) =>
    flickering &&
    `
     animation: flickerButtonAnimation2 1s linear infinite   
     `};

  animation: ${(props) =>
    (props.isInputFieldSelected || props.inputValueNotEmpty) &&
    'movePlaceholder 0.2s forwards'};
  color: var(--grey-pale3);

  @keyframes movePlaceholder {
    to {
      left: 7px;
      top: -9px;
      font-size: 11px;
      width: fit-content;
      height: fit-content;
      margin: 0;
      padding-left: 3px;
      color: var(--grey-pale1);
      padding-right: 3px;
    }
  }

  @keyframes flickerPlaceholderAnimation {
    0%,
    100% {
      color: var(--grey-pale3);
    }
    50% {
      color: var(--twd-aqua);
    }
  }
`;

export const SelectBoxArrowDiv = styled.div`
  position: absolute;
  pointer-events: none;
  background: inherit;
  right: 0;
  top: 0;
  width: 12px;
  height: calc(100% - 20px);
  margin: 10px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectBoxArrow = styled.div<{
  isInputFieldSelected: boolean;
  disabled?: boolean;
}>`
  content: ' ';
  border-left: 6px solid;
  border-left-color: ${(props) =>
    props.disabled ? 'var(--grey-pale3)' : 'var(--grey-pale1)'};
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  animation: ${(props) =>
    props.isInputFieldSelected && 'rotateArrowDown 0s forwards'};
  z-index: 2;
  @keyframes rotateArrowDown {
    to {
      border-bottom: none;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid var(--grey-pale1);
    }
  }
`;
