import { useEffect, useState } from 'react';
import { putFromAPI } from './services/apiCalls';
import { PlanningType } from './components/Planning/PlanningType';
import { UserType } from './App.types.';

export const useEditMode = (
  selectedPlanning: PlanningType | null,
  setIsLoading: (isLoading: boolean) => void,
  currentUser: UserType | null
) => {
  const [inEditMode, setInEditMode] = useState<boolean>(false);
  const [alreadyInUse, setAlreadyInUse] = useState<string>('');

  useEffect(() => {
    if (currentUser && selectedPlanning && selectedPlanning.editor) {
      setAlreadyInUse(
        selectedPlanning.editor === currentUser.employee
          ? ''
          : selectedPlanning.editor
      );
    } else {
      setAlreadyInUse('');
    }
  }, [selectedPlanning, currentUser]);

  async function enterEditMode(selectedPlanning: PlanningType) {
    setIsLoading(true);
    if (currentUser && currentUser.employee) {
      const response = await putFromAPI(
        `/budget-planning/edit/${selectedPlanning.id}/${currentUser.employee}`,
        {}
      );
      if (response.ok) {
        setInEditMode(true);
      } else {
        const editor = (await response.json()).detail.data.error;
        setAlreadyInUse(editor);
      }
    }
    setIsLoading(false);
  }

  async function leaveEditMode(selectedPlanning: PlanningType) {
    setIsLoading(true);
    const response = await putFromAPI(
      `/budget-planning/remove_edit/${selectedPlanning.id}`,
      {}
    );
    if (response.ok) {
      console.log('very nice');
    } else {
      console.log('You have no access');
    }
    setInEditMode(false);
    setIsLoading(false);
  }

  return { inEditMode, alreadyInUse, enterEditMode, leaveEditMode };
};
