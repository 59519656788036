import { useEffect, useState } from 'react';
import { MultiSelectBox } from './MultiSelectBox/MultiSelectBox';
import { MultiSelectModal } from './MultiSelectModal/MultiSelectModal';
import { BaseSelect, SelectOption } from '../BaseSelect/BaseSelect';
import { useChangeToSelectOption } from '../Select.hooks';

interface MultiSelectProps<T extends object> {
  options: T[];
  optionLabels: (option: T) => string;
  onChange: (value: T | null, isSelected: boolean) => void;
  itemType: string;
  autoSelectAll?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

export function MultiSelect<T extends object>(props: MultiSelectProps<T>) {
  const { selectOptions, clickOnOption } = useChangeToSelectOption(
    props.options,
    props.optionLabels,
    (option: SelectOption<T>, isSelected: boolean) => {
      props.onChange(option.option, isSelected);
    },
    0,
    true
  );

  const [openModal, setOpenModal] = useState<boolean>(false);

  const onOptionClicked = (option: SelectOption<T>) => {
    clickOnOption(option);
  };

  return (
    <BaseSelect
      options={selectOptions}
      onClick={onOptionClicked}
      callback={() => setOpenModal(false)}
    >
      <MultiSelectBox
        options={selectOptions}
        openModal={openModal}
        setOpenModal={setOpenModal}
        itemType={props.itemType}
        placeholder={props.placeholder}
        disabled={props.disabled}
      />
      {openModal && (
        <MultiSelectModal
          options={selectOptions}
          clickedOnOption={onOptionClicked}
        />
      )}
    </BaseSelect>
  );
}
