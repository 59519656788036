import { GridColDef, GridValueSetter } from '@mui/x-data-grid-premium';
import { addDays, format, getDay, isBefore, startOfWeek } from 'date-fns';
import {
  PhaseCodeEntry,
  PhaseData,
  RateEntry,
  RowEntry,
} from '../PlanningType';
import { fromStringToDate } from './TimeFunctions';

export function formatCurrencyFields(currency: string, value: number): string {
  /**
   * Formats the currency of cells for the grouped cells.
   *
   * @param {GridRenderCellParams} params - The parameters of the row
   * @param {boolean} showAllValues - Show values in the ungrouped rows
   *
   * **/
  return (
    currency +
    ` ` +
    value.toLocaleString('nl-NL', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  );
}

export interface ColumnValue {
  partId: string;
  value: number;
  rate: number;
  spent: number;
  hourSpent: number;
}

export function createPlannedColumn(
  field: string,
  headerName: string,
  className: string,
  rates: RateEntry,
  fieldDate: Date,
  inEditMode: boolean
): GridColDef {
  // The hours should be multiplied by the rate, in the formatter we reverse this. This is the only way that we
  // can show the aggregated value in the group column.

  let classN = className;
  const fieldWeekDate = startOfWeek(fieldDate, { weekStartsOn: 1 });
  const currentWeekDate = startOfWeek(new Date(), { weekStartsOn: 1 });
  if (isBefore(fieldWeekDate, currentWeekDate)) {
    classN = className + '-past';
  }
  return {
    field,
    headerName,
    type: 'number',
    width: className === 'weekColumn' ? 85 : 35,
    minWidth: className === 'weekColumn' ? 85 : 10,
    editable: inEditMode,
    disableReorder: true,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    cellClassName: classN,
    valueGetter:
      className === 'weekColumn'
        ? (_, row, column, _1) => {
            const columnValue: ColumnValue = {
              partId: row.partId,
              value: getWeekSum(row, column),
              rate: row.rate ? rates[row.rate] : 1,
              spent: 0,
              hourSpent: 0,
            };
            return columnValue;
          }
        : undefined,
    valueSetter: className === 'weekColumn' ? setWeekSum : undefined,
    valueFormatter:
      className === 'weekColumn'
        ? (columnValue: ColumnValue, _1, _2, _3) => {
            const normalValue: number = columnValue ? columnValue.value : 0;
            return normalValue === 0 ? null : normalValue;
          }
        : (value) => (value === 0 ? null : value),
  };
}

function getWeekSum(row: RowEntry, column: any): number {
  const columnDate = fromStringToDate(column.field);
  let sumWeek = 0;

  // Since the column is a Sunday, we can check the other dates
  for (let i = 6; i >= 2; i--) {
    // Calculate the column string for the current day
    const columnString = format(addDays(columnDate, -i), 'dd-MM-yyyy');
    sumWeek += (row as any)[columnString] ?? 0;
  }
  return sumWeek;
}

const setWeekSum: GridValueSetter<RowEntry> = (
  value,
  row,
  column
): RowEntry => {
  // In this function we divide the sum value over the other week columns

  const columnDate = fromStringToDate(column.field);
  let sumWeek = value;
  let newDict: { [field: string]: number | null } = {};
  let counter = 5;

  // Since the column is a Sunday, we can check the other dates
  for (let day_index = 6; day_index >= 2; day_index--) {
    // Calculate the column string for the current day
    const columnString = format(addDays(columnDate, -day_index), 'dd-MM-yyyy');
    let maxHoursPerDay = Math.ceil(sumWeek / counter);

    if (sumWeek < maxHoursPerDay) {
      newDict[columnString] = sumWeek ? sumWeek : null;
      sumWeek -= sumWeek;
    } else {
      newDict[columnString] = maxHoursPerDay ? maxHoursPerDay : null;
      sumWeek -= maxHoursPerDay;
    }

    counter -= 1;
  }

  return { ...row, ...newDict };
};

export function getPlannedSum(row: RowEntry): number {
  let sumPlanned = 0;
  for (const column in row) {
    let columnDate = fromStringToDate(column);
    const columnWeekDate = startOfWeek(columnDate, { weekStartsOn: 1 });
    const currentWeekDate = startOfWeek(new Date(), { weekStartsOn: 1 });
    let columnDay = getDay(columnDate);
    // Column date is not Sunday
    if (
      !isBefore(columnWeekDate, currentWeekDate) &&
      columnDay !== 0 &&
      columnDay
    ) {
      sumPlanned += row[column] ?? 0;
    }
  }
  return sumPlanned;
}

export function getSpent(
  groupName: any,
  phases: PhaseCodeEntry,
  phaseData: PhaseData,
  currency: string,
  hourView: boolean
) {
  const phaseId = Object.entries(phases).find(
    ([key, value]) => value === groupName
  )?.[0];
  if (phaseId) {
    const actualCosting = phaseData[phaseId]?.actualCosting || 0; // Get amount spent
    const actualCostingHours = phaseData[phaseId]?.actualCostingHours || 0; // Get hours spent
    // Format with currency symbol if not hourView
    return hourView ? (actualCostingHours === 0 ? 0 : actualCostingHours)
                    : (actualCosting === 0 ? 0 : formatCurrencyFields(currency, actualCosting));
  } else {
    return 0;
  }
}
