import React, { useState } from 'react';
import { PlanningType } from '../../../PlanningType';
import { styled } from 'styled-components';
import { ReactComponent as PlusIcon } from '../../../../../assets/Plus.svg';
import { AddPlanningSectionProps } from './AddPlanningSection.types';
import { CreationModal } from '../../../../Modals/CreationModal';
import { Tooltip } from '@mui/material';

export function AddPlanningSection(props: AddPlanningSectionProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [planningName, setPlanningName] = useState<string>('');

  const onAddButtonClicked = () => setOpenModal(true);

  const changePlanningName = (value: string) => {
    setPlanningName(value);
  };

  const closeModal = () => {
    setPlanningName('');
    setOpenModal(false);
  };

  const CreatePlanningButtonOnClick = async () => {
    const newPlanning: PlanningType | null =
      await props.createPlanning(planningName);
    if (newPlanning) {
      props.setAllPlannings([...props.allPlannings, newPlanning]);
    }
    props.setIsLoading(false);
    closeModal();
  };

  return (
    <StyledAddPlanningDiv>
      <CreationModal
        open={openModal}
        closeModal={closeModal}
        headerText={'Create new plan'}
        value={planningName}
        setValue={changePlanningName}
        createPlanning={CreatePlanningButtonOnClick}
        blueButtonText={'Create'}
      />
      <Tooltip title={'Make a new planning'} placement={'top'} arrow>
        <AddButton onClick={onAddButtonClicked}>
          <PlusIcon />
        </AddButton>
      </Tooltip>
    </StyledAddPlanningDiv>
  );
}

const StyledAddPlanningDiv = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: end;
`;

const AddButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: inherit;
  border: 0;

  svg {
    fill: var(--grey-pale3);
  }

  &:hover {
    svg {
      fill: var(--twd-aqua);
    }

    cursor: pointer;
  }
`;
