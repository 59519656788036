import React, { MouseEvent, useState } from 'react';
import { PlanningType } from '../../../PlanningType';
import { styled } from 'styled-components';
import { ReactComponent as CrossIcon } from '../../../../../assets/Cross.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/Copy.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/EditBadge.svg';
import { PlanningButtonContentProps } from './PlanningButtonOperations.types';
import { PlanningIconButton } from '../../../../IconButton/IconButton';
import { WarningModal } from '../../../../Modals/WarningModal';
import { Tooltip } from '@mui/material';

export function PlanningButtonOperations(props: PlanningButtonContentProps) {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const closeModal = () => {
    setOpenDeleteModal(false);
  };
  const onEditButtonClicked = (event: MouseEvent<HTMLElement>) => {
    props.updateEditModalData(props.option.option as PlanningType);
    event.stopPropagation();
  };
  const clickOnCopyPlanning = async (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    await props.copyPlanning(props.option.option as PlanningType);
  };

  const clickOnDeleteIcon = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenDeleteModal(true);
  };
  const clickOnDeletePlanning = async () => {
    await props.deletePlanning(props.option.option as PlanningType);
  };

  return (
    <IconButtonDiv>
      <WarningModal
        open={openDeleteModal}
        closeModal={closeModal}
        deletePlanning={clickOnDeletePlanning}
        planningName={(props.option.option as PlanningType).name}
      />
      <Tooltip
        title={'Change the name of this planning'}
        placement={'top'}
        arrow
        disableInteractive
      >
        <PlanningIconButton
          normalColor={'var(--aqua-pale1)'}
          hoverColor={'var(--twd-aqua)'}
          onClick={onEditButtonClicked}
        >
          <EditIcon />
        </PlanningIconButton>
      </Tooltip>
      <Tooltip
        title={'Copy this planning'}
        placement={'top'}
        arrow
        disableInteractive
      >
        <PlanningIconButton
          normalColor={'var(--aqua-pale1)'}
          hoverColor={'var(--twd-aqua)'}
          onClick={async (e: MouseEvent<HTMLElement>) =>
            await clickOnCopyPlanning(e)
          }
        >
          <CopyIcon />
        </PlanningIconButton>
      </Tooltip>
      <Tooltip
        title={'Delete this planning'}
        placement={'top'}
        arrow
        disableInteractive
      >
        <PlanningIconButton
          normalColor={'var(--aqua-pale1)'}
          hoverColor={'var(--twd-signal)'}
          onClick={clickOnDeleteIcon}
        >
          <CrossIcon />
        </PlanningIconButton>
      </Tooltip>
    </IconButtonDiv>
  );
}

const IconButtonDiv = styled.div`
  display: flex;
  align-items: center;
  z-index: 11;
`;
